import React, { useState } from 'react'
import { observer } from 'mobx-react'
import Modal from '../../Components/Modal'
import { Selector } from '../../Components/Selector'
import RenderOnQueries from '../Layout/RenderOnQueries'
import Table from '../../Components/Table'
import { observable, computed, action, makeObservable } from 'mobx'
import InvoiceLineItemCollection from '../../State/Collections/InvoiceLineItemCollection'
import _ from 'lodash'
import { canViewExpenseTracking } from '../../State/Permissions/HasPermissions'

const dropdownOptions = {
    any: { label: 'any', value: null },
    yes: { label: 'yes', value: true },
    no: { label: 'no', value: false },
}
class state {
    @observable selectedExpenses = new Set()
    constructor() {
        makeObservable(this)
    }
    @action selectExpense(e) {
        this.selectedExpenses.add(e)
    }
    @action deselectExpense(e) {
        this.selectedExpenses.delete(e)
    }
    @action clearSelectedExpenses() {
        this.selectedExpenses.clear()
    }
}
const State = new state()

export default observer(
    ({ modalId, invoice, project, phase, trackedExpenses, position }) => {
        const [billable, setBillable] = useState('any')
        const [inDateRange, setInDateRange] = useState('any')
        const [remainingCost, setRemainingCost] = useState('any')
        const expenses = (phase?.expenses || project.expenses).filter((e) => {
            const expenseItems = trackedExpenses.filter(
                (li) =>
                    li.expenseId === e.id && !li.deletedAt && !li.beenInvoiced
            )
            return (
                ((dropdownOptions[billable].value == null ||
                    e.billable === dropdownOptions[billable].value) &&
                    (dropdownOptions[inDateRange].value == null ||
                        (e.startDate <= invoice.endDate &&
                            e.endDate >= invoice.startDate) ===
                            dropdownOptions[inDateRange].value) &&
                    (dropdownOptions[remainingCost].value == null ||
                        (!e.beenInvoiced &&
                            e.cost -
                                (invoice.cachedData.expenses[e.id]
                                    ?.previousBilled || 0) >
                                0) === dropdownOptions[remainingCost].value)) ||
                _.sum(expenseItems.map((li) => li.cost || 0)) > 0 ===
                    dropdownOptions[remainingCost].value
            )
        })
        const expenseColumns = [
            {
                id: 'selected',
                label: '',
                width: 3,
                type: 'checkbox',
                editable: (r) => true,
                value: (r) => {
                    return State.selectedExpenses.has(r)
                },
                onChange: (r) => (v) => {
                    State.selectedExpenses.has(r)
                        ? State.deselectExpense(r)
                        : State.selectExpense(r)
                },
            },
            {
                id: 'expense',
                label: 'Expense',
                width: 16,
                type: 'text',
                editable: (r) => false,
                value: (r) => r.name,
            },
            {
                id: 'startDate',
                label: 'Start Date',
                width: 10,
                type: 'date',
                editable: (r) => false,
                value: (r) => r.startDate,
            },
            {
                id: 'endDate',
                label: 'End Date',
                width: 10,
                type: 'date',
                editable: (r) => false,
                value: (r) => r.endDate,
            },
            canViewExpenseTracking()
                ? {
                      id: 'trackedCost',
                      label: 'Tracked Cost',
                      width: 10,
                      type: 'currency',
                      editable: (r) => false,
                      value: (r) => {
                          const expenseItems = trackedExpenses.filter(
                              (li) =>
                                  li.expenseId === r.id &&
                                  !li.deletedAt &&
                                  !li.beenInvoiced
                          )
                          return _.sum(expenseItems.map((li) => li.cost || 0))
                      },
                  }
                : null,
            {
                id: 'remainingCost',
                label: 'Remaining Cost',
                width: 10,
                type: 'currency',
                editable: (r) => false,
                value: (r) => {
                    const expenseLineItems = invoice.lineItems.filter(
                        (li) => li.expenseId === r.id
                    )
                    return (
                        r.cost -
                        (invoice.cachedData.expenses[r.id]?.previousBilled ||
                            0) -
                        _.sum(expenseLineItems.map((li) => li.amount || 0))
                    )
                },
            },
            {
                id: 'totalCost',
                label: 'Total Cost',
                width: 10,
                type: 'currency',
                editable: (r) => false,
                value: (r) => r.cost,
            },
            {
                id: 'billable',
                label: 'Billable',
                width: 10,
                type: 'boolean',
                editable: (r) => false,
                value: (r) => r.billable,
                style: (r) => {
                    return {
                        color: !r || r?.billable ? null : 'red',
                    }
                },
            },
        ].filter(Boolean)
        return (
            <Modal
                modalId={modalId}
                heading="Add Expense Line Items"
                bodyStyle={{ padding: 0 }}
                width="60em"
                saveLabel={'Add Expenses'}
                saveOptions={[
                    canViewExpenseTracking()
                        ? { id: 'tracked', label: 'Tracked' }
                        : null,
                    { id: 'remaining', label: 'Remaining' },
                    { id: 'total', label: 'Total' },
                ].filter(Boolean)}
                defaultSaveOption={
                    canViewExpenseTracking() ? 'tracked' : 'remaining'
                }
                onSave={(saveOption) => {
                    State.selectedExpenses.forEach((e) => {
                        let value = 0
                        const expenseItems = trackedExpenses.filter(
                            (li) =>
                                li.expenseId === e.id &&
                                !li.deletedAt &&
                                !li.beenInvoiced
                        )
                        switch (saveOption?.id) {
                            case 'tracked':
                                value = _.sum(
                                    expenseItems.map((li) => li.cost || 0)
                                )
                                break
                            case 'total':
                                value = e.cost
                                break
                            default: // remaining
                                const expenseLineItems =
                                    invoice.lineItems.filter(
                                        (li) => li.expenseId === e.id
                                    )
                                value =
                                    e.cost -
                                    (invoice.cachedData.expenses[e.id]
                                        ?.previousBilled || 0) -
                                    _.sum(
                                        expenseLineItems.map(
                                            (li) => li.amount || 0
                                        )
                                    )
                                break
                        }
                        InvoiceLineItemCollection.add(
                            {
                                projectId: invoice.projectId,
                                contactId: invoice.contactId,
                                invoiceId: invoice.id,
                                phaseId:
                                    e.phase?.id || invoice.project.rootPhaseId,
                                billingType: 'reimbursement',
                                lineItemType: 'expense',
                                description: e.name,
                                unitQuantity: 1,
                                unitCost: value,
                                expenseId: e.id,
                                expenseItemIds: expenseItems.map((li) => li.id),
                                isTaxed: true,
                                position,
                            },
                            { trackUpdates: true }
                        )
                        expenseItems.forEach((te) =>
                            te.update({ beenInvoiced: true })
                        )
                        position++
                    })
                    State.clearSelectedExpenses()
                }}
            >
                <RenderOnQueries
                    queryIds={[
                        {
                            collection: 'projectExpenses',
                            fields: [
                                'projectId',
                                'phaseId',
                                'cost',
                                'startDate',
                                'endDate',
                                'name',
                                'quantity',
                                'unitCost',
                                'billable',
                                'beenInvoiced',
                            ],
                            filters: [`projectId == "${project.id}"`],
                        },
                    ]}
                >
                    <div className="flex items-center justify-around p-[1em] border-b border-[#ccc]">
                        <div className="flex items-center">
                            <span
                                className="flex-0-0-auto"
                                style={{ marginRight: '1em' }}
                            >
                                Billable:
                            </span>
                            <Selector
                                selectedOption={dropdownOptions[billable]}
                                onChange={(o) => setBillable(o)}
                                options={Object.keys(dropdownOptions)}
                                optionLabel={(o) => o?.label || o}
                                style={{ width: '5.5em' }}
                                variant="secondary"
                                className="[&_span]:!text-xs"
                            />
                        </div>
                        <div className="flex items-center">
                            <span
                                className="flex-0-0-auto"
                                style={{ marginRight: '1em' }}
                            >
                                In Date Range:
                            </span>
                            <Selector
                                selectedOption={dropdownOptions[inDateRange]}
                                onChange={(o) => setInDateRange(o)}
                                options={Object.keys(dropdownOptions)}
                                optionLabel={(o) => o?.label || o}
                                style={{ width: '5.5em' }}
                                variant="secondary"
                                className="[&_span]:!text-xs"
                            />
                        </div>
                        <div className="flex items-center">
                            <span
                                className="flex-0-0-auto"
                                style={{ marginRight: '1em' }}
                            >
                                {`${canViewExpenseTracking() ? 'Tracked / ' : ''}Remaining Cost:`}
                            </span>
                            <Selector
                                selectedOption={dropdownOptions[remainingCost]}
                                onChange={(o) => setRemainingCost(o)}
                                options={Object.keys(dropdownOptions)}
                                optionLabel={(o) => o?.label || o}
                                style={{ width: '5.5em' }}
                                variant="secondary"
                                className="[&_span]:!text-xs"
                            />
                        </div>
                    </div>
                    <div className="flex items-center justify-center p-[1.5em]">
                        <Table
                            showHeader={true}
                            columns={expenseColumns}
                            rows={expenses}
                        />
                    </div>
                </RenderOnQueries>
            </Modal>
        )
    }
)
