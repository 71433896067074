import { useMemo } from 'react'
import { DataTable } from '@2/components/data-table/data-table'
import { useDataTable } from '@2/components/data-table/hooks/use-data-table'
import { createGenerateColumnDef } from '@/version2/components/data-table/helpers/generate-column-def'
import { StaffInterface } from '../staff-page/staff.interface'
import {
    useData,
    useDateRange,
    setSelectedMonth,
    setSelectedRow,
    useSelectedRow,
    useSelectedMonth,
    getSelectedRow,
    getSelectedMonth,
    updateProjectedRevenueCellValue,
} from './revenue-forecast-store'
import {
    format,
    eachMonthOfInterval,
    parse,
    startOfMonth,
    addMonths,
    subMonths,
} from '@2/utils/date-fns'
import { trpc } from '@/system/trpc'
import useSaveToast from '@/version2/components/hooks/save-toast'
import * as React from 'react'
import { setOrganisationReport } from '../invoice-report/invoice-report-store'
import { sortingDateFn, sortingTextFn } from '@/version2/types'
import { FormatCurrency } from '@/Utils/Localisation/CurrencyFormatter'
import SessionStore from '@/State/SessionStore'
import { canEditRevenueTargets } from '@/State/Permissions/HasPermissions'
// import { DataTableAdvancedToolbar } from '@2/components/data-table/advanced/data-table-advanced-toolbar'

interface MonthlyData {
    [key: string]: number
}

interface StatusGroup {
    id: string
    projects: Project[]
    [key: string]: any
}

interface Project {
    id: string
    name: string
    phases: Phase[]
    [key: string]: any
}

interface Phase {
    id: string
    name: string
    [key: string]: any
}

interface TableRow {
    status: string
    projectId: string | null
    phaseId: string | null
    name: string
    total: number
    [key: string]: any
}

export const RevenueForecastProjectTable = () => {
    useSelectedRow()
    useSelectedMonth()
    const showSaveToast = useSaveToast({
        onSave: async (row, value, month) => {
            const phaseRow = row.depth === 2 ? row : null
            const projectRow =
                row.depth === 1
                    ? row
                    : row.depth === 2
                      ? row.getParentRow()
                      : null
            const statusRow =
                row.depth === 1
                    ? row.getParentRow()
                    : row.depth === 2
                      ? row.getParentRow().getParentRow()
                      : null
            setSelectedRow({
                status: statusRow?.original.status,
                projectId: projectRow?.original.projectId,
                phaseId: phaseRow?.original.phaseId,
            })
            await updateProjectedRevenueCellValue({
                month: format(month, 'yyyy-MM'),
                status: statusRow?.original.status,
                projectId: projectRow?.original.projectId,
                phaseId: phaseRow?.original.phaseId,
                value: value as number,
            })
        },
        loadingMessage: 'Saving forecast...',
        successMessage: 'Forecast saved successfully',
        errorMessage: 'Failed to save forecast',
    })
    const generateColumnDef = createGenerateColumnDef<TableRow>({
        onHideColumn: () => null,
    })

    const storeData = useData()
    const dateRange = useDateRange()

    const months = useMemo(
        () =>
            dateRange[0] && dateRange[1]
                ? eachMonthOfInterval({
                      start: parse(dateRange[0], 'yyyy-MM-dd', new Date()),
                      end: parse(dateRange[1], 'yyyy-MM-dd', new Date()),
                  })
                : [],
        [dateRange]
    )

    const columns = useMemo(
        () => [
            generateColumnDef({
                key: 'total',
                title: 'Total',
                type: 'string',
                size: 120,
                customValue: (row) => {
                    if (!row) return ''
                    const total =
                        row.pastActualTotal +
                        row.futureProjectedTotal +
                        Math.max(
                            row.currentActualTotal,
                            row.currentProjectedTotal
                        )
                    const formattedValue = `${FormatCurrency(total, { decimals: 0 })} / ${FormatCurrency(row.fee, { decimals: 0 })}`
                    return formattedValue
                },
                cellClassName: (row) => {
                    if (!row || !row.original.fee || !row.depth) return ''
                    const total =
                        row.original.pastActualTotal +
                        row.original.futureProjectedTotal +
                        Math.max(
                            row.original.currentActualTotal,
                            row.original.currentProjectedTotal
                        )
                    const roundedTotal = Math.round(total)
                    const roundedFee = Math.round(row.original.fee)
                    if (roundedTotal < roundedFee) return 'text-blue-500'
                    if (roundedTotal > roundedFee) return 'text-red-500'
                    return ''
                },
            }),
            generateColumnDef({
                key: 'status',
                title: 'Status',
                type: 'string',
                size: 120,
            }),
            generateColumnDef({
                key: 'project',
                title: 'Project',
                size: 120,
            }),
            generateColumnDef({
                key: 'phase',
                title: 'Phase',
                size: 120,
            }),
            ...months.map((month) =>
                generateColumnDef({
                    key: format(month, 'yyyy-MM'),
                    title: format(month, 'MMM yy'),
                    type: 'currency',
                    aggregationFn: 'sum',
                    size: 120,
                    customValue: (row) => {
                        const monthKey = format(month, 'yyyy-MM')
                        const projected = row[`${monthKey}-P`] || 0
                        const actual = row[`${monthKey}-A`] || 0
                        const currentMonthKey = format(new Date(), 'yyyy-MM')
                        if (monthKey < currentMonthKey) {
                            return actual
                        } else if (monthKey > currentMonthKey) {
                            return projected
                        }
                        return Math.max(actual, projected)
                    },
                    editable: (row) =>
                        row.depth > 0 &&
                        canEditRevenueTargets(SessionStore.user) &&
                        format(month, 'yyyy-MM') >=
                            format(new Date(), 'yyyy-MM'),
                    onChange: (row, value) => {
                        showSaveToast(row, value, month)
                    },
                    onClick: (row) => {
                        const phaseRow = row.depth === 2 ? row : null
                        const projectRow =
                            row.depth === 1
                                ? row
                                : row.depth === 2
                                  ? row.getParentRow()
                                  : null
                        const statusRow =
                            row.depth === 1
                                ? row.getParentRow()
                                : row.depth === 2
                                  ? row.getParentRow().getParentRow()
                                  : null
                        setSelectedRow({
                            status: statusRow?.original.status,
                            projectId: projectRow?.original.projectId,
                            phaseId: phaseRow?.original.phaseId,
                        })
                        setSelectedMonth(format(month, 'yyyy-MM'))
                    },
                    onFocus: (row) => {
                        const phaseRow = row.depth === 2 ? row : null
                        const projectRow =
                            row.depth === 1
                                ? row
                                : row.depth === 2
                                  ? row.getParentRow()
                                  : null
                        const statusRow =
                            row.depth === 1
                                ? row.getParentRow()
                                : row.depth === 2
                                  ? row.getParentRow().getParentRow()
                                  : null
                        setSelectedRow({
                            status: statusRow?.original.status,
                            projectId: projectRow?.original.projectId,
                            phaseId: phaseRow?.original.phaseId,
                        })
                        setSelectedMonth(format(month, 'yyyy-MM'))
                    },
                    cellClassName: (row) => {
                        const phaseRow = row.depth === 2 ? row : null
                        const projectRow =
                            row.depth === 1
                                ? row
                                : row.depth === 2
                                  ? row.getParentRow()
                                  : null
                        const statusRow =
                            row.depth === 1
                                ? row.getParentRow()
                                : row.depth === 2
                                  ? row.getParentRow().getParentRow()
                                  : null
                        const selectedRow = getSelectedRow()
                        const selectedMonth = getSelectedMonth()
                        const selected =
                            row.depth > 0 &&
                            selectedRow?.status ===
                                statusRow?.original.status &&
                            selectedRow?.projectId ===
                                projectRow?.original.projectId &&
                            selectedRow?.phaseId ===
                                phaseRow?.original.phaseId &&
                            selectedMonth === format(month, 'yyyy-MM')
                        return selected
                            ? 'outline outline-2 outline-blue-500'
                            : ''
                    },
                })
            ),
        ],
        [months]
    )

    const { table } = useDataTable({
        data: storeData,
        columns,
        visibleColumns: [
            'label',
            'total',
            // 'status',
            // 'project',
            // 'phase',
            ...months.map((month) => format(month, 'yyyy-MM')),
        ],
        groups: {
            enabled: true,
            columns: ['status', 'project'],
            leafRowLabel: 'Phase',
            // labelColumn: generateColumnDef({
            //     key: 'name',
            //     title: 'Name',
            //     size: 120,
            // }),
        },
        defaultSort: [
            {
                id: 'label',
                desc: false,
            },
        ],
        sortingFns: {
            sortingTextFn,
            sortingDateFn,
        },
    })

    return (
        <div className="mt-8">
            <h2 className="text-xl font-semibold mb-4 pl-4">Project Revenue</h2>
            <DataTable table={table} showTotals={true} className="w-full" />
        </div>
    )
}

export default RevenueForecastProjectTable
