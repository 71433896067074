import { useMemo } from 'react'
import { DataTable } from '@2/components/data-table/data-table'
import { useDataTable } from '@2/components/data-table/hooks/use-data-table'
import { createGenerateColumnDef } from '@/version2/components/data-table/helpers/generate-column-def'
import { useOverheadExpenses, useDateRange } from './revenue-forecast-store'
import { format, eachMonthOfInterval, parse } from '@2/utils/date-fns'
import { sortingDateFn, sortingTextFn } from '@/version2/types'

interface TableRow {
    id: string
    name: string
    total: number
    [key: string]: any
}

export const RevenueForecastOverheadTable = () => {
    const generateColumnDef = createGenerateColumnDef<TableRow>({
        onHideColumn: () => null,
    })

    const overheadExpenses = useOverheadExpenses()
    const dateRange = useDateRange()

    const months = useMemo(
        () =>
            dateRange[0] && dateRange[1]
                ? eachMonthOfInterval({
                      start: parse(dateRange[0], 'yyyy-MM-dd', new Date()),
                      end: parse(dateRange[1], 'yyyy-MM-dd', new Date()),
                  })
                : [],
        [dateRange]
    )

    const tableData = useMemo(() => {
        if (!Array.isArray(overheadExpenses)) return []

        return overheadExpenses.map((expense) => ({
            id: expense.id,
            name: expense.name,
            label: expense.name,
            total: Object.entries(expense)
                .filter(([key]) => key.match(/^\d{4}-\d{2}$/))
                .reduce((sum, [_, value]) => {
                    const numValue =
                        typeof value === 'string'
                            ? parseFloat(value)
                            : (value as number)
                    return sum + (numValue || 0)
                }, 0),
            ...months.reduce(
                (acc, month) => {
                    const monthKey = format(month, 'yyyy-MM')
                    const value = expense[monthKey]
                    acc[monthKey] =
                        typeof value === 'string'
                            ? parseFloat(value)
                            : (value as number) || 0
                    return acc
                },
                {} as Record<string, number>
            ),
        }))
    }, [overheadExpenses, months])

    const columns = useMemo(
        () => [
            generateColumnDef({
                key: 'name',
                title: 'Expense Name',
                type: 'text',
                size: 200,
            }),
            generateColumnDef({
                key: 'total',
                title: 'Total',
                type: 'currency',
                size: 120,
                aggregationFn: 'none',
            }),
            ...months.map((month) =>
                generateColumnDef({
                    key: format(month, 'yyyy-MM'),
                    title: format(month, 'MMM yy'),
                    type: 'currency',
                    aggregationFn: 'sum',
                    size: 120,
                })
            ),
        ],
        [months]
    )

    const visibleColumns = useMemo(
        () => [
            'name',
            'total',
            ...months.map((month) => format(month, 'yyyy-MM')),
        ],
        [months]
    )

    const { table } = useDataTable({
        data: tableData,
        columns,
        visibleColumns,
        sortingFns: {
            sortingTextFn,
            sortingDateFn,
        },
        // groups: {
        //     enabled: true,
        //     columns: [],
        //     leafRowLabel: 'Expense',
        // },
    })

    if (!tableData.length) return null

    return (
        <div className="mt-8">
            <h2 className="text-xl font-semibold mb-4 pl-4">
                Overhead Expenses
            </h2>
            <DataTable table={table} showTotals={true} className="w-full" />
        </div>
    )
}

export default RevenueForecastOverheadTable
